<template>
    <div class="error-page">
        <div class="error-page__image"></div>
        <div class="error-page__text">
            Кажется что-то пошло не так!
            Мы не смогли найти страницу, которую Вы запрашиваете.
            Перейдите на Главную страницу и попробуйте еще раз.
        </div>
        <router-link to="/" class="es-button">Перейти на главную</router-link>
    </div>
</template>

<script>

export default {
  name: 'Error-page',
  components: {},
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/forms";

.error-page {
    min-height: calc(100vh - 216px);
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 22px;
    padding: 60px 23px;

    @media only screen and (min-width: 768px) {
        min-height: calc(100vh - 194px);
    }

    &__image {
        background: url("/images/404.jpg");
        width: 274px;
        height: 120px;
        background-size: cover;
        margin:80px auto 30px auto;
    }

    &__text {
        max-width: 515px;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        padding: 0 40px;
        margin-bottom: 40px;

        @media only screen and (min-width: 768px) {
            font-size: 18px;
            line-height: 150%;
        }
    }

    .es-button {
        max-width: 300px;
    }
}

</style>
