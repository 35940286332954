<template>
    <div class="meditations-list">
        <router-link :to="getPreviousLink()"
                     v-if="getPreviousLink() !== ''"
                     class="meditations-item arrow"
        >
        </router-link>
        <router-link v-for="link in list" :key="link.id"
                     :to="link.url"
                     class="meditations-item meditations-item--active meditations-item--unlocked"
                     :class="{
                       'unlocked': link.isPayed,
                       'active': link.isActive
                     }"
                     v-html="link.nameHTML"
        >
        </router-link>
        <router-link :to="getNextLink()"
                     v-if="getNextLink() !== ''"
                     class="meditations-item arrow arrow-right"
        >
        </router-link>
    </div>
</template>

<script>

export default {
  name: 'MeditationsList',
  components: {},
  data() {
    return {
      complete: false,
    };
  },
  computed: {
    fullList() {
      return this.$store.state.meditations.meditationsList;
    },
    list() {
      return this.$store.state.meditations.currentMeditationGroup.list;
    },
    currentGroupID() {
      return this.$store.state.meditations.currentMeditationGroup.groupName;
    },
  },
  methods: {
    getPreviousLink() {
      let link = '';

      this.fullList.forEach((item, index) => {
        if (item.groupName === this.currentGroupID && index !== 0) {
          const prevGroup = this.fullList[index - 1];
          const prevGroupLastUrl = prevGroup.list[prevGroup.list.length - 1].url;
          link = `/meditation-group/${prevGroup.groupName}/${prevGroupLastUrl}`;
        }
      });

      return link;
    },
    getNextLink() {
      let link = '';
      const fullCount = this.fullList.length;

      this.fullList.forEach((item, index) => {
        if (item.groupName === this.currentGroupID && index !== (fullCount - 1)) {
          const prevGroup = this.fullList[index + 1];
          link = `/meditation-group/${prevGroup.groupName}/${prevGroup.list[0].url}`;
        }
      });

      return link;
    },
    moveActiveToCenter() {
      const elementScroll = document.querySelector('.meditations-list');
      const linksList = document.querySelectorAll('.meditations-item');
      let stopActiveFlag = true;
      let finalScrollWidth = 0;
      let leftMargin = window.innerWidth / 2;

      linksList.forEach((item) => {
        if (item.classList.contains('active')) {
          stopActiveFlag = false;
          leftMargin -= (item.offsetWidth / 2);
        }

        if (stopActiveFlag) {
          finalScrollWidth += item.offsetWidth + 24;
        }
      });

      elementScroll.scroll({
        top: 0,
        left: finalScrollWidth - leftMargin,
        behavior: 'smooth',
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.moveActiveToCenter();
    }, 300);
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.moveActiveToCenter();
      }, 150);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";
    .meditations-list {
        height: 70px;
        width: 100%;
        margin: 25px 0 10px 0;
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
        padding: 0 20px;
        box-sizing: border-box;
        position: relative;
        z-index: 8;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;

        @media only screen and (min-width: 768px) {
            height: 80px;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &:after {
            display: flex;
            content: '';
            width: 20px;
            height: 56px;
            flex-shrink: 0;
        }
    }

    .meditations-item {
        height: 56px;
        position: relative;
        border-radius: 10px;
        background: $darkBlue;
        color: $white;
        display: inline-block;
        margin: 0 4px;
        flex-shrink: 0;
        padding: 8px 15px;
        box-sizing: border-box;
        font-size: 14px;
        text-decoration: none;
        font-family: $textFont;
        letter-spacing: 0.4px;
        line-height: 42px;
        font-weight: 600;

        @media only screen and (min-width: 768px) {
            font-size: 20px;
            height: 60px;
            line-height: 60px;
            padding: 0 20px;
            font-family: $titleFont;
        }

        &:before {
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: url("/images/lock-icon-blue.svg") $white 50% 50% no-repeat;
            content: '';
            display: block;
            left: -6px;
            bottom: -12px;
        }

        &.active {
            background: var(--main-color);
        }

        &.arrow {
            line-height: 42px;
            font-weight: 400;
            width: 40px;

            &:before {
                background: url("/images/right-arrow.svg")  50% 50% no-repeat;
                background-size: 10px;
                left: 50%;
                top: 50%;
                margin-top: -11px;
                margin-left: -14px;

                @media only screen and (min-width: 768px) {
                    margin-top: -13px;
                }
            }

            &.arrow-right {
                transform: rotate(180deg);
            }
        }

        &.unlocked {
            &:before {
                background: url("/images/check-icon-green.svg") $white 50% 50% no-repeat;
                background-size: 16px;
            }
        }
    }
</style>
