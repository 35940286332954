<template>
    <header class="secondary-header" :class="groupData.meditationTheme">
        <div class="secondary-header__title">{{title}}</div>
        <div @click="moveTo" class="secondary-header__action-button"></div>
    </header>
</template>

<script>

export default {
  name: 'BaseSecondaryHeader',
  props: {
    title: String,
    backParams: String,
  },
  components: {},
  data() {
    return {
      complete: false,
    };
  },
  computed: {
    groupData() {
      return this.$store.state.meditations.currentMeditationGroup;
    },
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
  },
  methods: {
    moveTo() {
      if (this.getUserMail !== '') {
        this.$router.push({ name: 'Cabinet' });
      } else {
        this.$router.push({ name: 'Home', params: { slide: this.backParams } });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.secondary-header {
    height: 60px;
    padding: 20px 24px 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    &__title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        padding-right: 100px;
        max-width: 150px;
        height: 44px;
        position: relative;
        top: 4px;

        @media only screen and (min-width: 768px) {
            font-size: 26px;
            max-width: 500px;
            line-height: 40px;
        }
    }

    &__action-button {
        width: 24px;
        height: 24px;
        background: $main-blue;
        border-radius: 50%;
        cursor: pointer;
        position: fixed;
        right: 24px;
        z-index: 10;

        /* ipad */
        @media only screen and (min-width: 768px) {
            right: calc(50% - 360px)
        }

        &:before {
            width: 100%;
            height: 100%;
            display: flex;
            background: url("/images/close-icon-white.svg") no-repeat 50% 50%;
            content: '';
            background-size: 8px;
        }
    }

    &.dark {
        .secondary-header__title {
            color: $white;
        }
    }
}
</style>
