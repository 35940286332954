<template>
  <div class="landing-page">
    <Header isNotFixed="true"></Header>
    <Error></Error>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Error from '@/components/general/Error.vue';
import Header from '@/components/header/BaseHeader.vue';
import Footer from '@/components/general/Footer.vue';

export default {
  name: 'InstaPage',
  components: {
    Error, Header, Footer,
  },
};
</script>
