<template>
    <div class="meditation-actions">
        <template v-if="currentMeditation.isPayed">
            <button class="es-button" @click="showPlayer()">{{getTitle()}}</button>
            <a href="https://t.me/elena_psychosomatics"
               target="_blank"
               class="es-button es-button--bordered"
            >
                Обсудить в Телеграме
            </a>
        </template>
        <template v-else-if="isNewMeditationLanding">
            <button class="es-button" @click="startPayment()">Купить</button>
            <button class="es-button es-button--bordered" @click="showPlayer()">
                Слушать фрагмент
            </button>
        </template>
        <template v-else>
            <button class="es-button" @click="startFullCoursePayment('standart')">
                <template v-if="isUserAuthed">Докупить “Стандарт”</template>
                <template v-else="">Купить “Стандарт”</template>
                за {{numberWithSpaces(getPriceForFullCourse.standart)}} руб
            </button>
            <button class="es-button es-button--bordered"
                    @click="startFullCoursePayment('premium')"
            >
                <template v-if="isUserAuthed">Докупить “Расширенный”</template>
                <template v-else="">Купить “Расширенный”</template>
                за {{numberWithSpaces(getPriceForFullCourse.premium)}} руб
            </button>
        </template>
        <button class="es-button es-button--link"
                @click="showAuth()"
                v-if="getUserMail === ''"
        >
            Восстановить покупки
        </button>
        <button class="es-button es-button--link"
                @click="goToPersonalCabinet()"
                v-if="getUserMail !== '' && !currentMeditation.isPayed"
        >
            Восстановить покупки
        </button>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MediatationActionButtons',
  components: {},
  data() {
    return {
      complete: false,
      isPixelCartEventSended: false,
      isUserAuthed: this.$store.getters.getUserMail !== '',
    };
  },
  watch: {
    $route() {
      this.isPixelCartEventSended = false;
    },
  },
  computed: {
    isNewMeditationLanding() {
      return this.$store.state.meditations.currentMeditationGroup.groupName === 'new-section';
    },
    currentMeditationGroup() {
      return this.$store.state.meditations.currentMeditationGroup;
    },
    currentMeditation() {
      return this.$store.state.meditations.currentMeditation;
    },
    preparedInfoCourse() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    preparedInfo() {
      return this.$store.getters.getMeditationsInfo;
    },
    preparedCourseInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    isCurrentPackagePayed() {
      return this.$store.getters.isCurrentPackagePayed;
    },
    isLandingSaleTimeEnabled() {
      return this.$store.state.user.isLandingSaleTimeEnabled;
    },
    getPriceForFullCourse() {
      const price = {
        standart: 0,
        premium: 0,
        standartFull: this.preparedCourseInfo.priceForNoPayed,
        premiumFull: this.preparedCourseInfo.priceForNoPayed + 15000,
      };

      const courseInfo = this.preparedCourseInfo;
      const hasNoPayments = courseInfo.priceForNoPayed === courseInfo.totalPrice;

      if (hasNoPayments && !this.isLandingSaleTimeEnabled) {
        price.standart = 17700;
      } else if (hasNoPayments) {
        price.standart = 5900;
      } else {
        price.standart = Math.round(this.preparedCourseInfo.priceForNoPayed / 3);
      }

      price.premium = price.standart + 15000;

      return price;
    },
    getPrices() {
      const price = {
        fullCource: this.preparedInfoCourse.priceForNoPayed,
        singleModule: this.currentMeditation.discrPrice,
      };

      const discount = this.currentMeditationGroup.saleForAll / 100;
      const fullPrice = this.preparedInfo.priceForNoPayed;

      price.singleModule = fullPrice - (fullPrice * discount);

      if (this.isCurrentPackagePayed) {
        price.singleModule -= (price.singleModule * discount);
      }

      price.fullCource = Math.round(price.fullCource);
      price.singleModule = Math.round(price.singleModule);

      return price;
    },
  },
  methods: {
    goToPersonalCabinet() {
      this.$store.commit('setUpdateMeditationSource', 'meditation');
      this.$store.dispatch('updateMeditations');
    },
    showPlayer() {
      this.$store.commit('showPlayer');

      if (!this.currentMeditation.isPayed) {
        const initiateEventID = Math.floor(Math.random() * (1e12 + 1));
        const currentUrl = this.$store.getters.getLocalUrl;

        if (typeof window.fbq !== 'undefined') {
          window.fbq('track', 'ViewContent', {
            content_ids: [this.currentMeditation.id.toString()],
            currency: 'USD',
            content_type: 'product',
            value: this.currentMeditation.discrPrice,
          }, {
            eventID: initiateEventID,
          });
        }

        axios
          .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
            amount: this.currentMeditation.discrPrice,
            eventName: 'ViewContent',
            email: this.getUserMail,
            id: initiateEventID,
          });
      }
    },
    startPayment() {
      this.$store.commit('showPayment');
      this.$store.commit('markMeditationForPayByID', {
        id: this.currentMeditation.id,
      });
      this.$store.commit('setNewMeditationPagePayment', true);
      this.$store.commit('setLandingPaymentFullPrice', 1500);
      this.$store.commit('setLandingPaymentPriceToPay', 975);

      if (!this.isPixelCartEventSended) {
        const initiateEventID = Math.floor(Math.random() * (1e12 + 1));
        const currentUrl = this.$store.getters.getLocalUrl;

        if (typeof window.fbq !== 'undefined') {
          window.fbq('track', 'AddToCart', {
            content_ids: [this.currentMeditation.id.toString()],
            content_type: 'product',
            currency: 'USD',
            value: this.currentMeditation.discrPrice,
          }, {
            eventID: initiateEventID,
          });
        }

        axios
          .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
            amount: this.currentMeditation.discrPrice,
            eventName: 'AddToCart',
            email: this.getUserMail,
            id: initiateEventID,
          });

        this.isPixelCartEventSended = true;
      }
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    startFullCoursePayment(type) {
      this.$store.commit('setLandingPagePayment', true);
      this.$store.commit('markCourseMeditationsForPay');
      this.$store.commit('setLandingPaymentType', type);
      this.$store.commit('setLandingPaymentPriceToPay', this.getPriceForFullCourse[type]);
      this.$store.commit('setPriceToPay', this.getPriceForFullCourse[type]);

      if (type === 'premium') {
        this.$store.commit('setLandingPaymentFullPrice', this.getPriceForFullCourse.premiumFull);
      }

      if (type === 'standart') {
        this.$store.commit('setLandingPaymentFullPrice', this.getPriceForFullCourse.standartFull);
      }

      this.$store.commit('showPayment');

      if (!this.isPixelCartEventSended) {
        const initiateEventID = Math.floor(Math.random() * (1e12 + 1));
        const currentUrl = this.$store.getters.getLocalUrl;

        if (typeof window.fbq !== 'undefined') {
          window.fbq('track', 'AddToCart', {
            content_ids: [this.currentMeditation.id.toString()],
            content_type: 'product',
            currency: 'USD',
            value: this.currentMeditation.discrPrice,
          }, {
            eventID: initiateEventID,
          });
        }

        axios
          .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
            amount: this.currentMeditation.discrPrice,
            eventName: 'AddToCart',
            email: this.getUserMail,
            id: initiateEventID,
          });

        this.isPixelCartEventSended = true;
      }
    },
    getTitle() {
      return this.currentMeditation.type === 'mediatation' ? 'Начать медитацию' : 'Начать вебинар';
    },
    showAuth() {
      this.$router.push({ name: 'Auth' });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.meditation-actions {
    position: fixed;
    bottom: 0;
    padding: 0 24px 15px 24px;
    width: 100%;
    max-width: $mobile-max ;
    box-sizing: border-box;
    background: rgba($white, 0.8);

    @media only screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 20px;

        &:before {
            width: 100%;
            height: 100%;
            background-color: var(--main-color);
            opacity: 0.15;
            z-index: 0;
            content: '';
            left: 0;
            top: 0;
            position: absolute;
            display: block;
        }
    }
}

 .es-button {
     background: var(--main-color);
     z-index: 5;

     &--bordered {
         border: solid var(--main-color) 1px;
         color: var(--main-color);
         background: $white;
     }

     &--link {
         background: transparent;
         line-height: 20px;
         font-size: 12px;
         height: 20px;
     }

     @media only screen and (min-width: 768px) {
         width: calc(50% - 20px);
         margin: 10px 9px;
     }
 }
</style>
